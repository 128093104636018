import React from 'react';
import Image from './Image.js';
import './PersonCard.css';
import { Icon } from 'antd';

class PersonCard extends React.Component {
  constructor(props) {
    super(props);
//    this.props.email = "mailto:" + this.props.email;
//    this.props.tel = "tel:" + this.props.tel;
  }

  render() {
    return (
      <div className="person">
        <div className="personIcon">
          <Image image={this.props.icon} />
        </div>
        <div>
          <span>{this.props.name}</span> <br />
          <Icon type="mail" /> <a href={"mailto:" + this.props.email}>{this.props.email}</a> <br />
          <Icon type="phone" /> <a href={"tel:" + this.props.tel}>{this.props.tel}</a>
        </div>
      </div>
    )
  }
}

export default PersonCard;
