import React from 'react';

class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: ''
    }

    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
  }

  changeImage() {
    if (this.state.logo.indexOf('grey') > 0) {
      this.setState({ logo: '/img/' + this.props.image + '.png' })
    } else {
      this.setState({ logo: '/img/' + this.props.image + '_grey.png' })
    }
  }

  componentDidMount() {
    this.changeImage();
  }

  onMouseOver() {
    this.changeImage();
  }

  onMouseOut() {
    this.changeImage();
  }

  render() {
    return (
      <img
        src={this.state.logo}
        alt={this.props.name}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
      />
    )
  }
}

export default Image;
