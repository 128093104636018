import React, { Component } from 'react';
import 'antd/dist/antd.css';
import './App.css';
import { Row, Col, Icon } from 'antd';
import Image from './Image.js';
import PersonCard from './PersonCard.js';

class App extends Component {

  render() {
    if (window.location.hostname != "cloudops.hu") {
      return window.location.replace("https://cloudops.hu");
    };
    return (
      <div>
        <div className="logo">
          <img src="/img/logo.png" alt="Logó" />
        </div>
        <Row>
          <Col className="gutter-row welcomeText" xs={24} md={12}>
            <div className="welcomeTextHeadline">
              Üdvözöljük!
            </div>
            Csapatunk elhivatott a legújabb cloud technológiák bevezetésében.
            Hiszünk abban, hogy az alkalmazásokat megbízhatóbban lehet üzemeltetni cloud környezetben,
            és ezért arra törekszünk, hogy minél inkább megkönnyítsük a váltást ügyfeleinknek.
            <br /><br />
            Csapatunk tagjai több cloud szolgáltatónál szerzett több éves tapasztalattal rendelkező emberek
            akik a cloud szolgáltatások alapos ismerete mellett tudják az alkalmazások ideális üzemeltetési
            környezetét kialakítani.

          </Col>
          <Col className="gutter-row welcomeImage" xs={0} md={12}>
              <img src="/img/image.png" alt="Cloud" />
          </Col>
        </Row>
        <Row span={12}>
          <div className="logos">
            <span>Akik eddig is megbíztak bennünk:</span><br/>
            <Image image="minicrm" name="MiniCRM" />
            <Image image="sabeeapp" name="SabeeApp" />
            <Image image="healcloud" name="HealCloud" />
            <Image image="akriel" name="Akriel" />
          </div>
        </Row>
        <Row>
          <Col className="footer" span={24}>
            <span>Elérhetőségeink:</span><br /><br />
            <div>
              <PersonCard icon="tomcsi" name="Pásztor Tamás" email="tamas.pasztor@cloudops.hu" tel="+36 30 637 6128" />
              <PersonCard icon="csaba" name="Keszei Csaba" email="csaba.keszei@cloudops.hu" tel="+36 20 463 0228" />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default App;
